<template lang="pug">
.input-file(:class="classObject", @click="toggle")
  slot(name="activator")

  app-button(v-if="!$slots.activator", icon, transparent, :loading="loading")
    slot(name="icon" :toggle="toggle")

  input(
    ref="file",
    type="file",
    accept="image/png, image/jpeg, image/jpg, .heic",
    :disabled="disabled",
    @change="onFileChange"
  )
</template>

<script>
import AppButton from "@/components/global/actions/BaseButton.vue";

export default {
  name: "InputFile",

  props: {
    loading: Boolean,
    disabled: Boolean,
  },

  computed: {
    classObject: function () {
      return {
        "input-file--disabled": this.loading,
      };
    },
  },

  methods: {
    onFileChange() {
      this.$emit("input", this.$refs.file.files[0], this.$refs.file);
    },

    clear() {
      this.$refs.file.value = null;
      this.$emit("clear");
    },

    toggle() {
      this.$refs.file.click();
    },
  },

  components: {
    AppButton,
  },
};
</script>

<style lang="scss">
.input-file {
  position: relative;
  display: block;

  &-- {
    &disabled {
      pointer-events: none !important;
    }
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;
  }
}

</style>
